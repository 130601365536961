<template>
	<div class="shangHuYeWu" id="shangHuYeWu">
		<div class="wrap">
			<div class="header">
				<div class="pic-box">
					<img :src="detail['图片']" alt="">
				</div>
				<div class="content">
					<div class="title">{{detail["名称"]}}</div>
					<div class="introduce">
						<div class="label">产品简介：</div>
						<div class="txt">{{detail["产品简介"]}}</div>
					</div>
					<div class="condition">
						<div class="label">申请条件：</div>
						<div class="txt">{{detail["申请条件"]}}</div>
					</div>
					<div class="btns">
						<a class="join" href="https://merchant.unionpay.com/cas/login?service=http%3A%2F%2Fmerchant.unionpay.com%2Fjoin%2Fmchntsign%2Fsaveprod%3FprodId%3D1&subSysId=CBB89F194ABC330C28AD3D1CD55C8D04&oip=MTkuMTMuODkuMTI6MTUxOTA%3D">我要入网</a>
						<a class="test" href="https://merchant.unionpay.com/cas/login?service=http%3A%2F%2Fmerchant.unionpay.com%2Fjoin%2Fmchntsign%2Fsaveprod%3FprodId%3D1&subSysId=CBB89F194ABC330C28AD3D1CD55C8D04&oip=MTkuMTMuODkuMTI6MTUxOTA%3D">我要测试</a>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="tab-list" >
					<div class="item" :class="item == tab_list_active_name ? 'active':''" v-for="(item,index) in tab_list" :key="index" @click="tab_list_active_name = item">{{item}}</div>
				</div>
				<div class="content"  :style="{padding: '5px',boxSizing: 'border-box'}" v-if="isMobile">
					<div style="width: 100%;"
					  v-if="tab_list_active_name == '用户使用场景'" v-html="formatRichText($options.filters.formatterRichJson(detail['用户使用场景']))"></div>
					<div v-if="tab_list_active_name == '使用特点'" v-html="formatRichText($options.filters.formatterRichJson(detail['使用特点']))"></div>
					<div v-if="tab_list_active_name == '运营服务'" v-html="formatRichText($options.filters.formatterRichJson(detail['运营服务']))"></div>
				</div>
				<div class="content" :style="{padding: '24px',boxSizing: 'border-box'}" v-else>
					<div  v-if="tab_list_active_name == '用户使用场景'" v-html="$options.filters.formatterRichJson(detail['用户使用场景'])"></div>
					<div v-if="tab_list_active_name == '使用特点'" v-html="$options.filters.formatterRichJson(detail['使用特点'])"></div>
					<div v-if="tab_list_active_name == '运营服务'" v-html="$options.filters.formatterRichJson(detail['运营服务'])"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['name'],
		data() {
			return {
				detail: {},
				tab_list: [],
				tab_list_active_name: ''
			};
		},
		watch: {
			name: {
				handler(val) {
					this.detail = this.$global.shangHuYeWuJson[val]
					this.tab_list = []
					this.tab_list_active_name = '用户使用场景'
					if (Object.keys(this.detail).indexOf('用户使用场景') > -1) {
						this.tab_list.push('用户使用场景')
					}
					if (Object.keys(this.detail).indexOf('使用特点') > -1) {
						this.tab_list.push('使用特点')
					}
					if (Object.keys(this.detail).indexOf('运营服务') > -1) {
						this.tab_list.push('运营服务')
					}
					console.log("this.detail: ",this.detail);
				},
				immediate: true
			}
		},
		filters: {
			formatterRichJson(val) {
				return val.replace(/\"/g, '\"').replace(/\/upload/g, 'http://www.sissms.cn/upload')
			}
		}
	}
		            	
</script>

<style lang="scss" scoped>
	@import 'shangHuYeWu.scss';
	@import 'shangHuYeWuMedia.scss';
</style>